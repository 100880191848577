import { get, post, put, remove } from "@/api/config";

// == 品牌管理 ==
export const BrandQuery = (query) => get("v1/brand/list" + query);

export const BrandCreate = (params) => post("v1/brand", params);

export const BrandRemove = (id) => remove(`v1/brand/${id}`);

export const BrandUpdate = (id, params) => put(`v1/brand/${id}`, params);

export const BrandDetail = (id) => get(`v1/brand/query/${id}`);

export const BrandAssetQuery = (id, query) => get(`v1/brand/asset/${id}` + query);
