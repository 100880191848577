import { get, post, put, remove } from "@/api/config";

// == 市场 ==
export const MarketQuery = (query) => get("v1/nftonline/list" + query);

export const MarketCreate = (params) => post("v1/nftonline", params);

export const MarketRemove = (id) => remove(`v1/nftonline/${id}`);

export const MarketUpdate = (id, params) => put(`v1/nftonline/${id}`, params);

export const MarketDetail = (id) => get(`v1/nftonline/query/${id}`);
//  一键售罄
export const NftSellOut = (id) => post(`v1/nftonline/sellout/${id}`);
// 获取单价列表
export const PriceQuery = () => get("v1/nftonline/prices");

export const NftOver = (id) => post(`v1/nftonline/closed/${id}`);

// 修改优先购参数
export const AheadBuyUpdate = (id, params) => post(`v1/nftonline/aheadbuy/${id}`, params);
