import {
    get,
    post,
    put,
    remove
} from '@/api/config'


// == 藏品 ==
export const CollectionQuery = query => get('v1/collection/list' + query);

export const CollectionCreate = params => post('v1/collection', params);

export const CollectionRemove = id => remove(`v1/collection/${id}`);

export const CollectionUpdate = (id, params) => put(`v1/collection/${id}`, params);

export const CollectionDetail = id => get(`v1/collection/query/${id}`);