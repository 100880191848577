import { get, post, put, remove } from "@/api/config";

// == nft ==
export const NftQuery = (query) => get("v1/nft/list" + query);

export const NftCreate = (params) => post("v1/nft", params);

export const NftRemove = (id) => remove(`v1/nft/${id}`);

export const NftUpdate = (id, params) => put(`v1/nft/${id}`, params);

export const NftDetail = (id) => get(`v1/nft/query/${id}`);

// 3D文件上传接口
export const ModelFilesUpload = (params) => post("v1/nft/upload3DZip", params);



